var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "draft_page d-flex flex-column align-start" },
    [
      _c(
        "div",
        { staticClass: "search_group d-flex align-center flex-shrink-0" },
        [
          _c("div", { staticClass: "icon" }),
          _c("el-input", {
            staticClass: "input",
            attrs: { placeholder: "输入内容, 按Enter进行搜索", clearable: "" },
            on: {
              clear: function ($event) {
                return _vm.clearSearch()
              },
            },
            nativeOn: {
              keydown: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.doSearch($event)
              },
            },
            model: {
              value: _vm.keyword,
              callback: function ($$v) {
                _vm.keyword = $$v
              },
              expression: "keyword",
            },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "draft_content d-flex flex-column" }, [
        _c(
          "div",
          { staticClass: "list_group d-flex flex-wrap" },
          [
            _vm._l(_vm.draft_list, function (item, index) {
              return [
                _c("thumb-task", {
                  key: index,
                  staticClass: "draft_unit",
                  attrs: {
                    item: item,
                    menu: true,
                    draft: true,
                    show_time: true,
                    "can-set-label": false,
                  },
                  on: {
                    handleClick: function ($event) {
                      return _vm.openTaskBox(item)
                    },
                  },
                }),
              ]
            }),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.loading,
                    expression: "loading",
                  },
                ],
                staticClass: "loading d-flex justify-center",
              },
              [_c("loading-icon")],
              1
            ),
          ],
          2
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loadmore,
                expression: "loadmore",
              },
            ],
            staticClass: "loadmore flex-shrink-0 cp",
            on: {
              click: function ($event) {
                return _vm.loadmoreList()
              },
            },
          },
          [_vm._v("加载更多")]
        ),
        _vm.nomore
          ? _c("div", { staticClass: "nomore flex-shrink-0" }, [
              _vm._v("没有更多了"),
            ])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }