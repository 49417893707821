<template>
  <div class="draft_page d-flex flex-column align-start">
    <div class="search_group d-flex align-center flex-shrink-0">
      <div class="icon"></div>
      <el-input
        v-model="keyword"
        placeholder="输入内容, 按Enter进行搜索"
        class="input"
        clearable
        @keydown.enter.native="doSearch($event);"
        @clear="clearSearch();"
      ></el-input>
    </div>
    <div class="draft_content d-flex flex-column">
      <div class="list_group d-flex flex-wrap">
        <template
          v-for="(item, index) in draft_list"
        >
          <thumb-task
            :key="index"
            :item="item"
            :menu="true"
            :draft="true"
            :show_time="true"
            :can-set-label="false"
            class="draft_unit"
            @handleClick="openTaskBox(item)"
          ></thumb-task>
        </template>
        <div class="loading d-flex justify-center"
          v-show="loading"
        >
          <loading-icon></loading-icon>
        </div>
      </div>
      <div class="loadmore flex-shrink-0 cp" v-show="loadmore"
        @click="loadmoreList();"
      >加载更多</div>
      <div class="nomore flex-shrink-0" v-if="nomore">没有更多了</div>
    </div>
  </div>
</template>
<script>
import Unit from '@/components/FunTemplate/TemplateUnit.vue';
import ThumbTask from '@/components/Common/ThumbTask'
import LoadingIcon from '@/components/basic/loadingIcon';
export default {
  components: {
    Unit,
    ThumbTask,
    LoadingIcon
  },
  data(){
    return {
      keyword: '',
      loading: false,
      search_type: false,
      loadmore: false,
      nomore: false,
    }
  },
  computed:{
    draft_list(){
      return this.$store.state.draft_list;
    }
  },
  watch: {
    keyword: function(val){
      if(val === ''){
        if(this.search_type){
          this.clearSearch();
        }
      }
    }
  },

  methods: {
    openTaskBox(item){
      let data = {
        taskId: item.id,
      }
      let config = {
        edit:true,
        jump: false,
        draft: true,
        relevancy: true, // 是否加载关联数据
        select_target: true
      }
      this.$api.moduleTask.openTask({taskId:data.taskId, config});
    },
    init(){
      this.getDraftList({});
    },
    /**
     * @description: 获取草稿列表
     * @param {*} keyword
     * @param {*} startId
     * @return {*}
     */
    getDraftList({keyword= '', start= ''}){
      this.$global.preventDuplicateClicks(async ()=>{
        this.$store.commit('set_draft_list', []);
        this.loading = true;
        this.nomore = false;
        this.loadmore = false;
        const res = await this.$api.draft.getDraftList({keyword, start: 0});
        if(res.contents.list){
          this.$store.commit('set_draft_list', res.contents.list);
          if(res.contents.list.length<20){
            this.nomore = true;
          }else{
            this.loadmore = true;
          }
        }
        this.loading = false;
      })

    },
    loadmoreList(){
      this.$global.preventDuplicateClicks(async ()=>{
        this.loading = true;
        const {keyword} = this;
        this.nomore = false;
        this.loadmore = false;
        const res = await this.$api.draft.getDraftList({keyword, start: this.draft_list.length});
        if(res.contents.list){
          if(res.contents.list.length<20){
            this.nomore = true;
          }else{
            this.loadmore = true;
          }
          // const newList = [this.$store.state.draft_list, res.contents.list];
          const newList = this.$store.state.draft_list.concat(res.contents.list);
          // console.log(newList);
          this.$store.commit('set_draft_list', newList);
        }
        this.loading = false;

      })

    },
    clearSearch(){
      this.search_type = false;
      this.getDraftList({});
    },
    doSearch(e){
      // console.log(e);
      if(e.keyCode === 229){
        return;
      }
      if(this.keyword !== ''){
        this.search_type = true;
      }else{
        this.search_type = false;
      }
      this.getDraftList({keyword: this.keyword});
    }
  },
  async mounted() {
    this.init();
  },
}
</script>
<style lang="scss" scoped>
.draft_page{
  @include bbox;
  padding: 20px;
  height: 100%;
  width: 100%;
  .search_group{
    height: 40px;
    background: #f3f3f3;
    border-radius: 20px;
    padding: 0 30px 0px;
    margin-bottom: 10px;
    .icon{
      width: 14px;
      height: 14px;
      background-image: url(~@/assets/images/common/search_icon.png);
      margin-right: 10px;
    }
    .input{
      @include placeholder;
      background-color: transparent;
      outline: none;
      border: none;
      width: 300px;
    }
  }
  .draft_content{
    width: 100%;
  }
  .list_group{
    width: 100%;
  }
  .draft_unit{
    margin: {
      right: 10px;
      bottom: 10px;
    }
  }
  .loadmore{
    font-size: 14px;
    width: 100%;
    text-align: center;
    color: $main_blue;
    padding: 10px 0;
  }
  .nomore{
    font-size: 14px;
    color: #999;
    width: 100%;
    text-align: center;
    padding: 10px 0;
  }
  .loading{
    padding: 0 20px;
    height: 140px;
    margin-bottom: 10px;
  }
}
</style>
<style>
  /* el-input */
  .draft_page .input .el-input__inner{
    border:none;
    background-color: transparent;
    font-size:14px;

    font-weight:400;
    color:#666;
    padding: 0 10px;
  }
</style>
